<template>
  <div class="row">
    <va-button
      class="ml-auto md3 add-appointment"
      @click="create()"
    >
      Create Announcement
    </va-button>
  </div>
  <div class="row">
    <div
      class="flex sm12 md6 lg3"
      v-for="announcement in this.items"
      :key="announcement.id"
    >
      <va-card>
        <va-image
          :src="announcement.thumbnail"
          style="height: 200px;"
        />
        <va-chip v-for="tag in announcement.tags" :key="tag">{{ tag }}</va-chip>
        <va-card-title>{{ announcement.title }}</va-card-title>
        <va-card-content>{{ announcement.content.substring(0, 170) }}</va-card-content>
        <va-card-actions align="left">
          <va-button
            @click="edit(announcement.slug)"
            color="primary"
            size="small"
          >
            <va-icon name="edit"/>
            Edit
          </va-button>
          <va-button
            @click="removeAnnouncement(announcement.slug)"
            color="danger"
            size="small"
          >
            <va-icon name="delete"/>
            Delete
          </va-button>
        </va-card-actions>
      </va-card>
    </div>
  </div>
</template>

<script>
import { VaCard, VaCardTitle, VaCardContent, VaImage, VaCardActions, VaButton, VaIcon, VaChip } from "vuestic-ui";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Announcement",
  components: {
    VaCard,
    VaCardTitle,
    VaCardContent,
    VaImage,
    VaCardActions,
    VaButton,
    VaIcon,
    VaChip,
  },
  data() {
    return {
      items: []
    };
  },
  mounted() {
    this.fetchAnnouncementList();
  },
  computed: {
    ...mapGetters("announcement", {
      announcementList: "getAnnouncementList"
    })
  },
  methods: {
    ...mapActions("announcement", {
      fetchAnnouncementList: "fetchAnnouncementList",
      deleteAnnouncement: "deleteAnnouncement"
    }),
    create() {
      this.$router.push({ name: "announcement-create" });
    },
    removeAnnouncement(slug) {
      //TODO: Remove announcement from DOM
      this.deleteAnnouncement(slug);
    },
    edit(announcementSlug) {
      this.$router.push({
        name: "announcement-edit",
        params: { slug: announcementSlug }
      });
    }
  },
  watch: {
    announcementList: {
      handler(newValue) {
        if (newValue) {
          this.items = newValue;
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
